<template>
  <v-container class="p-3 mt-5">
    <loading-flux :value="loadingExcel"></loading-flux>
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-2">
        Bodega Móvil | Transferencias de Material
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="start" align-md="start" class="mt-1">
      <v-col cols="12" md="12" sm="12">
        <v-btn
          @click="openModalCreateTransferMaterials"
          color="primary text-white"
          small
          v-if="permissions.escritura"
        >
          <v-icon small class="mr-1">
            mdi-plus
          </v-icon>
          Realizar transferencia
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0 mt-1">
        <v-autocomplete
          v-model="filters.mobile_warehouse"
          clearable
          :items="mobileWarehouses"
          @input="getTransferMaterialsData(1)"
          class=""
          item-value="code"
          item-text="name_code"
          label="Filtrar por Bodega Móvil"
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0 mt-1">
        <v-autocomplete
          v-model="filters.project_code"
          clearable
          :items="projects.items ? projects.items : []"
          @input="getTransferMaterialsData(1)"
          @update:search-input="getProjectsData"
          @click:clear="getProjectsData"
          class=""
          hide-details
          item-text="name_code"
          no-data-text="Sin proyectos"
          :loading="loadingProjects"
          label="Filtrar por Bodega Destino"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0 mt-1"> </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0 mt-1">
        <v-text-field
          v-model="filters.filter"
          @input="getTransferMaterialsData(1)"
          clearable
          placeholder="Buscar:"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0 mt-1"> </v-col>
      <v-col cols="12" md="4" sm="12" class="mt-1">
        <v-btn
          class="float-right mt-5"
          color="blue darken-2 text-white"
          small
          @click="downloadExcelTransferMaterials"
          :disabled="!wrappedTransferMaterials.length"
        >
          <v-icon small class="mr-1">
            mdi-export
          </v-icon>
          Exportar
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-3 px-3 mb-2 mt-4"
      :headers="tableHeaders"
      :items="wrappedTransferMaterials"
      :items-per-page="20"
      :page.sync="filters.page"
      :loading="loading"
      loading-text="Cargando.."
      item-key="id"
      hide-default-footer
      no-data-text="Sin registros"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.created_at="{ item }">
        <span class="mt-2" style="font-size: 12px;">
          {{ new Date(item.created_at).toLocaleDateString() }}
        </span>
      </template>
      <template v-slot:item.origin_warehouse="{ item }">
        <span class=" mt-2" style="font-size: 11px;">
          {{ item.origin_warehouse }}
        </span>
      </template>
      <template v-slot:item.user="{ item }">
        <span class="mt-2" style="font-size: 11px;">
          {{ item.user.email }}
        </span>
      </template>
      <template v-slot:item.project="{ item }">
        <span class="mt-2" style="font-size: 10px;">
          {{ item.project.code }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalShowTransferMaterials(item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                x-small
                class="mx-2"
              >
                <v-icon>
                  mdi-view-list-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Ver materiales de la transferencia</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <div class="float-right">
      <v-pagination
        total-visible="8"
        v-model="filters.page"
        :length="transferMaterials ? transferMaterials.total_pages : 1"
      ></v-pagination>
    </div>
    <modal-create-transfer-materials
      ref="modalCreateTransferMaterials"
      :modalAction="dialogCreateTransferMaterials"
      :closeModalCreateTransferMaterials="closeModalCreateTransferMaterials"
      @transferMaterialsCreated="getTransferMaterialsData"
    />
    <modal-show-materials
      :modalAction="dialogShowTransferMaterials"
      :closeModalShowTransferMaterials="closeModalShowTransferMaterials"
      :currentTransferMaterials="currentTransferMaterials"
    ></modal-show-materials>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { TYPE_REQUESTS_MATERIALS } from "@/constants/requestMaterial";
import ModalCreateTransferMaterials from "./components/ModalCreateTransferMaterials.vue";
import ModalShowMaterials from "./components/ModalShowMaterials.vue";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import { authUsuarioComputed } from "@/state/helpers";

export default {
  components: { ModalCreateTransferMaterials, ModalShowMaterials },
  mounted() {
    this.getTransferMaterialsData();
    this.getWarehousesData();
    this.getProjectsData();
    this.permissions = permiso.obtenerPermisos(
      this.user,
      this.$router.currentRoute.name
    );
  },
  beforeDestroy() {
    this.clearProjects();
  },
  watch: {
    "filters.page": function(page) {
      this.getTransferMaterialsData(page);
    },
  },
  data() {
    return {
      permissions: {},
      dialogCreateTransferMaterials: false,
      dialogShowTransferMaterials: false,
      currentTransferMaterials: null,
      debounce: null,
      debounceTime: 500,
      debounceProjects: null,
      debounceProjectsTime: 500,
      loadingProjects: false,
      loadingExcel: false,
      filters: {
        page: 1,
        filter: "",
        mobile_warehouse: null,
        project_code: null,
      },
      pageCount: 0,
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          value: "actions",
          className: "px-0",
          width: "3%",
          sortable: false,
        },
        {
          text: "Código Bodega Móvil",
          sortable: false,
          width: "5%",
          align: "center",
          value: "origin_warehouse",
        },
        {
          text: "Bodega Destino",
          sortable: false,
          width: "5%",
          align: "center",
          value: "project",
        },
        {
          text: "Usuario",
          align: "center",
          value: "user",
          width: "3%",
          sortable: false,
        },
        {
          text: "Fecha de Creación",
          align: "center",
          value: "created_at",
          width: "5%",
          sortable: false,
        },
      ],
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: "Transferencias de Material",
          disabled: true,
          href: "/supplying/mobile-warehouses/material-transfers",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getProjects: "requestMaterialsSap/getProjects",
      getMobileWarehouses: "mobileWarehouse/getMobileWarehouses",
      sendDownloadExcelRequestMaterials:
        "requestMaterialsSap/downloadExcelRequestMaterials",
      clearProjects: "requestMaterialsSap/clearProjects",
      getTransferMaterials: "mobileWarehouse/getTransferMaterials",
    }),
    async getTransferMaterialsData(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function() {
        await self.getTransferMaterials({
          ...self.filters,
          get_type: TYPE_REQUESTS_MATERIALS.transfer_materials,
          page,
          filter: self.filters.filter ? self.filters.filter : undefined,
          project_code: self.filters.project_code
            ? self.filters.project_code.split(" - ")[0]
            : undefined,
          origin_filter: self.filters.mobile_warehouse
            ? self.filters.mobile_warehouse
            : undefined,
        });
      }, self.debounceTime);
    },
    async getWarehousesData(search = null) {
      await this.getMobileWarehouses();
    },
    async getProjectsData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") &&
          this.filters.project_code !== search)
      ) {
        this.loadingProjects = true;
        clearTimeout(this.debounceProjects);
        let self = this;
        this.debounceProjects = setTimeout(async function() {
          await self.getProjects({
            is_residential: true,
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingProjects = false;
        }, self.debounceProjectsTime);
      }
    },
    async downloadExcelTransferMaterials() {
      this.loadingExcel = true;
      const response = await this.sendDownloadExcelRequestMaterials({
        get_type: TYPE_REQUESTS_MATERIALS.transfer_materials,
      });
      let fileURL = window.URL.createObjectURL(new Blob([response]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `transferencias_material_bodega.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.loadingExcel = false;
    },
    openModalCreateTransferMaterials() {
      this.dialogCreateTransferMaterials = true;
    },
    closeModalCreateTransferMaterials() {
      this.dialogCreateTransferMaterials = false;
    },
    openModalShowTransferMaterials(transferMaterials) {
      this.currentTransferMaterials = transferMaterials;
      this.dialogShowTransferMaterials = true;
    },
    closeModalShowTransferMaterials() {
      this.currentTransferMaterials = null;
      this.dialogShowTransferMaterials = false;
    },
  },
  computed: {
    ...authUsuarioComputed,

    ...mapGetters({
      loading: "mobileWarehouse/loading",
      projects: "requestMaterialsSap/projects",
      transferMaterials: "mobileWarehouse/transferMaterials",
      mobileWarehouses: "mobileWarehouse/mobileWarehouses",
    }),
    wrappedTransferMaterials() {
      return this.transferMaterials.items;
    },
  },
};
</script>

<style></style>
